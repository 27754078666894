<template>
  <div>
    <app-collapse>

      <item-list-add-new
        v-if="getCurrentCategories().length > 0"
        :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
        :categories="getCurrentCategories()"
        @refetch-data="fetchCategories()"
      />

      <app-collapse-item
        title="Filters"
        is-visible
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mb-md-0"
          >
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-md-0"
                  >
                    <label>Status</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="statusFilter"
                      :options="statusOptions"
                      class="w-100"
                      :reduce="val => val.value"
                      @input="(val) => eventFilter('update:statusFilter', val)"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-md-0"
                  >
                    <label>Gender</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="genderFilter"
                      :options="genderOptions"
                      class="w-100"
                      :reduce="val => val.value"
                      @input="(val) => eventFilter('update:genderFilter', val)"
                    />

                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-md-0"
                  >
                    <label>Prices</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="priceFilter"
                      :options="pricesOptions"
                      class="w-100"
                      :reduce="val => val.value"
                      @input="(val) => eventFilter('update:priceFilter', val)"
                    />

                  </b-col>
                  <b-col
                    cols="6"
                    md="2"
                    class="mb-md-0"
                  >
                    <label>Order By</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="orderByFilter"
                      :options="orderByOptions"
                      class="w-100"
                      :reduce="val => val.value"
                      @input="(val) => eventFilter('update:orderByFilter', val)"
                    />
                  </b-col>

                  <b-col
                    cols="6"
                    md="2"
                    class="mb-md-0"
                  >
                    <label>Location</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="locationFilter"
                      :options="locationsOptions"
                      class="w-100"
                      :reduce="val => val.label"
                      @input="(val) => eventFilter('update:locationFilter', val)"
                    />
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="mb-md-0"
          >
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="6"
                    md="3"
                    class="mb-md-0"
                  >
                    <label>Group</label>
                    <v-select
                      v-if="getCurrentCategories().length > 0"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="groupFilter"
                      :options="resolveSubCategories()"
                      class="w-100"
                      :reduce="val => val"
                      @input="(val) => eventFilter('update:groupFilter', val)"
                      @option:selected="eventFilter('update:categoryFilter', null)"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                    class="mb-md-0"
                  >
                    <template
                      v-if="groupFilter != null && resolveSubCategories(groupFilter.value) && resolveSubCategories(groupFilter.value).length > 0"
                    >
                      <label>Category</label>
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="categoryFilter"
                        :options="resolveSubCategories(groupFilter.value)"
                        class="w-100"
                        :multiple="true"
                        :reduce="val => val"
                        @input="(val) => eventFilter('update:categoryFilter', val)"
                        @option:selected="eventFilter('update:subCategoryFilter', null)"
                      />
                    </template>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                    class="mb-md-0"
                  >
                    <template
                      v-if="groupFilter != null && categoryFilter != null && categoryFilter.length === 1 && resolveSubCategories(groupFilter.value, categoryFilter.map(data => (data.value)))"
                    >
                      <label>Sub Category</label>
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="subCategoryFilter"
                        :options="resolveSubCategories(groupFilter.value, categoryFilter.map(data => (data.value)))"
                        class="w-100"
                        :multiple="true"
                        :reduce="val => val"
                        @input="(val) => eventFilter('update:subCategoryFilter', val)"
                      />
                    </template>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="mb-md-0"
          >
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="6"
                    md="3"
                    class="mb-md-0"
                  >
                    <label>Family Filters</label>
                    <v-select
                      v-if="getCurrentTypes().length > 0"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="typeFilter"
                      :options="resolveFilters()"
                      class="w-100"
                      :reduce="val => val"
                      @input="(val) => eventFilter('update:typeFilter', val)"
                      @option:selected="eventFilter('update:filterFilter', null)"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="6"
                    class="mb-md-0"
                  >
                    <template
                      v-if="typeFilter != null && resolveFilters(typeFilter.value) && resolveFilters(typeFilter.value).length > 0"
                    >
                      <label>Filter</label>
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="filterFilter"
                        :options="resolveFilters(typeFilter.value)"
                        class="w-100"
                        :multiple="true"
                        :reduce="val => val"
                        @input="(val) => eventFilter('update:filterFilter', val)"
                      />
                    </template>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <div
      style="height: inherit"
      class="mt-1"
    >
      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="ecommerce-searchbar">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            md="8"
            lg="8"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="searchQuery"
                placeholder="Search Product"
                class="search-product"
              />
              <b-input-group-append is-text>
                <b-button
                  variant="primary"
                  class="btn-block"
                  @click="eventFilter('update:searchQueryProp', searchQuery)"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted mr-1"
                  />
                  <span class="text-nowrap">Search</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
            sm="12"
            class="mb-md-0 mt-1"
          >
            <!-- Item View Radio Button Group  -->
            <b-form-radio-group
              v-model="viewMode"
              class="list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
              @change="(val) => $emit('update:view-type', val)"
            >
              <b-form-radio
                value="grid"
                @click="viewType = 'grid'"
              >
                <feather-icon
                  icon="GridIcon"
                  size="18"
                />
              </b-form-radio>
              <b-form-radio
                value="list"
                @click="viewType = 'list'"
              >
                <feather-icon
                  icon="ListIcon"
                  size="18"
                />
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <!-- Add Item -->
          <b-col
            cols="12"
            md="2"
            sm="12"
            class="mb-md-0 mt-1"
          >
            <b-button
              v-if="$can('store', 'Product')"
              variant="primary"
              class="btn-block"
              @click="isAddNewItemSidebarActive = true"
            >
              <span class="text-nowrap">Add Item</span>
            </b-button>
          </b-col>

        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BFormRadioGroup, BFormRadio, BButton, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useShopUi } from '@/views/items/items/Items-thumbnail/useECommerceShop'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ItemListAddNew from '@/views/items/items/items-store/ItemListAddNew.vue'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import { ref } from '@vue/composition-api'
import useTypesList from '@/views/items/filters/filters-list/useTypesList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    ItemListAddNew,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    orderByFilter: {
      type: [String, null],
      default: null,
    },
    locationFilter: {
      type: [String, null],
      default: null,
    },
    priceFilter: {
      type: [String, null],
      default: null,
    },
    genderFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    orderByOptions: {
      type: Array,
      required: true,
    },
    locationsOptions: {
      type: [Array, null],
      required: true,
    },
    pricesOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      default: true,
    },
    // Prop filtraggio per categoria
    groupFilter: {
      type: [Object, null],
      default: null,
    },
    categoryFilter: {
      type: [Array, null],
      default: null,
    },
    subCategoryFilter: {
      type: [Array, null],
      default: null,
    },
    //
    // Prop filtraggio per type
    typeFilter: {
      type: [Object, null],
      default: null,
    },
    filterFilter: {
      type: [Array, null],
      default: null,
    },
    //
    // Filtro di ricerca
    searchQueryProp: {
      type: [String, null],
      default: null,
    },
    //
    // Prop per scegliere il tipo di vista
    viewType: {
      type: String,
      required: true,
    },
    //
  },
  data() {
    return {
      viewMode: null,
      sortDirDesc: false,
      searchQuery: null,
    }
  },
  watch: {
    viewType(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal !== '') {
        localStorage.setItem('userSettings', JSON.stringify({ itemView: newVal }))
      }
    },
    isSortDirDesc(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal !== '') {
        this.sortDirDesc = this.isSortDirDesc
      }
    },
    searchQueryProp(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.searchQuery = newVal
    },
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('userSettings')) && JSON.parse(localStorage.getItem('userSettings')).itemView) {
      this.viewMode = JSON.parse(localStorage.getItem('userSettings')).itemView
    } else {
      localStorage.setItem('userSettings', JSON.stringify({ itemView: 'grid' }))
      this.viewMode = JSON.parse(localStorage.getItem('userSettings')).itemView
    }
  },
  setup() {
    const isAddNewItemSidebarActive = ref(false)

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const {
      fetchCategories,
      fetchCategoriesById,
      getCurrentCategories,
      groupSelect,
      categorySelect,
    } = useCategoriesList()

    fetchCategories()

    const {
      fetchTypes,
      getCurrentTypes,
    } = useTypesList()

    fetchTypes()

    return {
      isAddNewItemSidebarActive,

      groupSelect,
      categorySelect,
      fetchCategories,
      fetchCategoriesById,
      getCurrentCategories,

      getCurrentTypes,

      itemView,
      itemViewOptions,
      totalProducts,
    }
  },
  methods: {
    eventFilter(event, val) {
      this.$emit(event, val)
      this.$emit('update:currentPageProp', 1)
    },
    resolveSubCategories(group = null, category = null) {
      if (Object.values(this.getCurrentCategories()).length > 0) {
        if (group === null) {
          return this.getCurrentCategories()
            .map((data, index) => ({
              label: `${data.name.it} - ${data.name.en}`,
              value: index,
              id: data.id,
            }))
        }
        if (category === null) {
          return this.getCurrentCategories()[group].categories.map((data, index) => ({
            label: `${data.name.it} - ${data.name.en}`,
            value: index,
            id: data.id,
          }))
        }
        return this.getCurrentCategories()[group].categories[category].subcategories.map((data, index) => ({
          label: `${data.name.it} - ${data.name.en}`,
          value: index,
          id: data.id,
        }))
      }
      return null
    },
    resolveFilters(type = null) {
      if (type === null) {
        return this.getCurrentTypes()
          .map((data, index) => ({
            label: `${data.name.it} - ${data.name.en}`,
            value: index,
            id: data.id,
          }))
      }
      return this.getCurrentTypes()[type].filters.map((data, index) => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: index,
        id: data.id,
      }))
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-sidebar
    v-if="getCurrentCategories().length > 0"
    id="add-new-item-sidebar"
    :visible="isAddNewItemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Item
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Group -->
          <validation-provider
            #default="validationContext"
            name="Group"
            rules="required"
          >
            <b-form-group
              label="Group"
              label-for="group"
            >

              <v-select
                id="group"
                v-model="itemData.group"
                input-id="group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="resolveSubCategories()"
                :clearable="false"
                :state="getValidationState(validationContext)"
                trim
                @input="itemData.category = null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Categories -->
          <validation-provider
            v-if="itemData.group != null && resolveSubCategories(itemData.group) && resolveSubCategories(itemData.group).length > 0"
            #default="validationContext"
            name="Categories"
            rules="required"
          >
            <b-form-group
              label="Categories"
              label-for="categories"
            >

              <v-select
                v-model="itemData.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="resolveSubCategories(itemData.group)"
                :clearable="false"
                input-id="categories"
                @input="itemData.subcategory = null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sub Categories -->
          <validation-provider
            v-if="itemData.category != null && resolveSubCategories(itemData.group, itemData.category) && resolveSubCategories(itemData.group, itemData.category).length > 0"
            #default="validationContext"
            name="Sub Categories"
            rules="required"
          >
            <b-form-group
              label="Sub Categories"
              label-for="sub categories"
            >

              <v-select
                v-model="itemData.subcategory"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="resolveSubCategories(itemData.group, itemData.category)"
                :clearable="false"
                input-id="sub categories"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div
            v-if="itemData.subcategory != null && resolveSubCategories(itemData.group, itemData.category).length > 0"
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Next
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInvalidFeedback, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, integer, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import vSelect from 'vue-select'

// Notification
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'

export default {

  components: {
    BSidebar,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BForm,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      integer,
      digits,
    }
  },
  setup() {
    const blankItemData = {
      group: null,
      category: null,
      subcategory: null,
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetitemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetitemData)

    const {
      fetchCategories,
      getCurrentCategories,
    } = useCategoriesList()

    return {
      // useCategoriesList
      fetchCategories,
      getCurrentCategories,
      //

      itemData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    onSubmit() {
      this.$store.commit('items/addDraftItem', this.itemData)
      this.$router.push({ name: 'items.store' })
    },
    resolveSubCategories(group = null, category = null) {
      if (group === null) {
        return this.getCurrentCategories()
          .map((data, index) => ({
            label: `${data.name.it} - ${data.name.en}`,
            value: index,
          }))
      }
      if (category === null) {
        return this.getCurrentCategories()[group].categories.map((data, index) => ({
          label: `${data.name.it} - ${data.name.en}`,
          value: index,
        }))
      }
      return this.getCurrentCategories()[group].categories[category].subcategories.map((data, index) => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: index,
      }))
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>

<template>
  <div
    style="height: inherit"
    class="mt-1"
  >

    <!-- Prodcuts -->
    <section class="grid-view">
      <b-card
        v-for="item in items.products"
        :key="item.id"
        :class="'ecommerce-card border-'+$parent.typePriceResolver(item.type_price, false)+' '+item.type_price"
        no-body
        :title="item.title.en"
      >

        <router-link
          v-if="$can('update', 'Product') || $can('store', 'Gallery') || $can('update', 'Gallery')"
          :to="$can('update', 'Product') ? {name: 'items.edit', params: { id: item.id }} : {name: 'items.gallery', params: { id: item.id }, hash: '#gallery',}"
          class="immagine"
        >
          <b-card-img
            :src="item.images.smallww.length > 0 ? item.images.smallww[0].link : require('@/assets/images/placeholder/the_cube_placeholder.png')"
          />
          <div class="cardfooter">
            <div
              v-if="item.brand"
              class="brand"
            >
              {{ item.brand }}
            </div>
            <div
              v-if="item.location"
              class="location"
            >
              {{ item.location }}
            </div>
            <div
              v-if="item.images.smallww.length > 0"
              class="images"
            >
              <feather-icon
                icon="ImageIcon"
              />
              {{ item.images.smallww.length }}
            </div>
          </div>
          <div
            v-if="item.price"
            class="prezzo"
          >
            <feather-icon
              :class="'text-'+$parent.typePriceResolver(item.type_price)"
              :icon="item.type_price !== 'normal' ? 'StarIcon' : ''"
            />
            <span :class="'item-price text-'+$parent.typePriceResolver(item.type_price)">
              €{{ item.price }}
            </span>
          </div>
        </router-link>
        <!-- Product Details -->
        <div class="codice">
          {{ item.code }}
        </div>
        <b-card-body>
          <b-card-title>
            {{ item.title.en }}
            <b-badge
              v-if="item.rent_status !== 'Available'"
              :variant="$parent.rentStatusResolver(item.rent_status)"
              class="badge"
            >
              {{ $parent.rentStatusTextResolver(item.rent_status) }}
            </b-badge>
          </b-card-title>
          <b-card-sub-title>
            <b-row>
              <b-col>
                {{ item.parent_category.category.name.en }} - {{ item.parent_category.subcategory.name.en }}
              </b-col>
              <b-col class="text-right">
                <b-badge
                  :variant="$parent.statusResolver(item.status)"
                  class="badge"
                >
                  {{ item.status }}
                </b-badge>
              </b-col>
            </b-row>
          </b-card-sub-title>
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-if="items && items.meta"
            v-model="currentPage"
            :total-rows="items.meta.total"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="(val) => $emit('update:currentPageProp', val)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BPagination,
  BCardSubTitle,
  BCardImg,
  BCardTitle,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { watch } from '@vue/composition-api'
// import useItemsList from '@/views/items/items/useItemList'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BCardBody,
    BPagination,
    BCardSubTitle,
    BCardImg,
    BCardTitle,
    BBadge,

    // SFC
  },
  directives: {
    Ripple,
  },
  props: {
    currentPageProp: {
      type: Number,
      default: null,
    },
    sortBy: {
      type: [String, null],
      default: null,
    },
    perPage: {
      type: Number,
      required: true,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
  },
  watch: {
    currentPageProp(newVal) {
      this.currentPage = newVal
    },
  },
  setup(props) {
    return {
      currentPage: props.currentPageProp,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.item-img {
  padding-top: 0.5rem;
  min-height: 15.85rem;
  display: flex;
  align-items: center;
}

.ecommerce-card {
  position: relative;

  .immagine {
    position: relative;
  }

  .codice, .prezzo {
    position: absolute;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 2rem;
    font-size: small;
  }

  .codice {
    top: 20px;
    left: 20px;
    background-color: rgba(250, 250, 250, 0.7);
    color: black;
  }

  .brand, .location, .images {
    display: inline-block;
    background-color: rgba(250, 250, 250, 0.7);
    color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 2rem;
    font-size: small;
    margin-right: 10px;
  }

  .images {
    margin-left: auto;
    margin-right: 0px;
  }

  .prezzo {
    top: 20px;
    right: 20px;
    background-color: rgba(250, 250, 250, 0.7);
    display: inline-flex;
    align-items: center;
  }

  .cardfooter {
    position: absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
    display: flex;
  }

  .card-body {
    display: flex;
    flex-direction: column;

    .card-subtitle {
      margin-top: auto;
    }
  }
}
</style>
